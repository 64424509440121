<template>
  <div v-if="value.variety.color
  && value.variety.product
  && (value.variety.product.variety === 'color-size'
   || value.variety.product.variety === 'color')">
    <span class="ml-1 text-color-666">{{ isColor ? 'رنگ ' : 'طرح ' }} :</span>
    <button class="bg-none">
      <ColorTag v-if="isColor" :style="'background-color:' + value.variety.color.code" />
      <img v-else-if="!isColor" :src="value.variety.color.image"
           style="width: 17px; height: 17px;" class=" border-radius7" alt="">
    </button>
    <span class="text-color-444 mr-1">{{value.variety.color.name}}</span>
  </div>
</template>

<script>
import ColorTag from '../svgs/ColorTag'

export default {
  name: "cartColor",
  props: {
    value: Object
  },
  components: {ColorTag},
  computed: {
    isColor() {
      return this.value.variety.color.multi == '0';
    }
  }
}
</script>

<style scoped>

</style>