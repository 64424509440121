<template>
  <svg width="17" height="17"
       viewBox="0 0 24 24" fill="none" class=" border-radius7" xmlns="http://www.w3.org/2000/svg">
    <path :style="pathStyles" :class="pathClasses" d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.62723" stroke="#fff" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: "ColorTag",
  props: {
    pathStyles: {
      type: String,
      default: ''
    },
    pathClasses: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>